import React from "react";
import serviceone from "../../../static/images/serviceone.png";
import Evolution from "../../../static/images/empbenefit.jpg";

export default function sectionone() {
  return (
    <div>
      <section
        class="lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          {/* <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={EmployeeBenefit}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                Employee Benefit Consulting
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Valuation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Valuation of Employee benefit schemes for
                            organizations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Standard Reports
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Reports based on International Accounting Standards
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Gap Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            Policy Structuring and Healthcare risk management
                            Recommendations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Support
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            HR teams for drafting of employee benefit strategy
                            and budget
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div>
              </div>
            </div>
          </div> */}

          <section class=" py-12 lg:px-8">
            <div class="max-w-7xl mx-auto">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                {/* <!-- Text Section --> */}
                <div class="bg-white rounded-lg overflow-hidden ">
                  <div class="p-6">
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">
                      Our Services
                    </h2>
                    <h3 class="mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl">
                      Employee Benefit Valuation and Structuring
                    </h3>
                    <p class="lg:text-lg leading-8 text-gray-600 mt-5">
                      Employee benefits play a pivotal role in attracting and
                      retaining top talent while ensuring long-term
                      organizational sustainability. At RJ Actuaries and
                      Consultants, we specialize in designing, evaluating, and
                      managing comprehensive employee benefit programs that
                      align with organizational goals and employee needs.
                    </p>
                    {/* <ul class="list-disc ml-6 mt-5">
                      <li>Designing</li>
                      <li>Managing</li>
                      <li>Evaluating</li>
                    </ul> */}
                  </div>
                </div>

                {/* <!-- Image Section --> */}
                <div class="bg-white rounded-lg overflow-hidden">
                  <img
                    class="w-full h-auto object-cover"
                    src={Evolution}
                    alt="Image"
                  />
                </div>
              </div>
            </div>

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto">
                <div class="text-center">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                    Our Services
                  </h2>
                 
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Gratuity and Pension Valuations
                      </h3>
                      <p class="text-gray-600">
                      Accurate actuarial valuations for gratuity and pension schemes, ensuring compliance with accounting standards and long-term sustainability.                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Post-Retirement Benefits Valuation
                      </h3>
                      <p class="text-gray-600">
                      Tailored solutions for medical allowances, insurance schemes, and other post-employment benefit plans to safeguard employees' futures.             </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Deferred Bonus and Long-Service Awards Valuation
                      </h3>
                      <p class="text-gray-600">
                      Strategic planning and valuation of deferred compensation and long-service rewards to strengthen employee retention strategies.

                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Leave Encashment and Leave Availment
                      </h3>
                      <p class="text-gray-600">
                      Actuarial evaluations for earned, sick, and other leave categories to align financial planning with employee entitlements.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Compliance Advisory
                      </h3>
                      <p class="text-gray-600">
                      Ensuring adherence to local and international accounting standards such as IAS 19 and AS 15, reducing compliance risks.
                      </p>
                    </div>
                    
                  </div>
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Customized Benefit Plan Design
                      </h3>
                      <p class="text-gray-600">
                      Developing sustainable and meaningful benefit plans tailored to specific organizational needs and budgets.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="container px-4 mx-auto mt-4">
          <p class="mb-6 text-xl text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why Chose RJAC ?
          </p>
          

          <section class="bg-white pb-6">
            {/* <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h1 class="md:text-2xl md:mb-5">
                    General and Health Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Capital and Risk Modelling</li>
                    <li>- Product design</li>
                    <li>- Pricing</li>
                    <li>- Reserving</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Liability Adequacy Testing</li>
                    <li>- Financial Condition Reports</li>
                    <li>
                      - Reinsurance optimization, appropriateness and
                      sufficiency assessment
                    </li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
                <div>
                  <h1 class=" md:text-2xl md:mb-5">
                    Life Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Risk Assessment</li>
                    <li>- Product design & filing</li>
                    <li>- Pricing</li>
                    <li>- Financial Reporting</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Reinsurance optimization</li>
                    <li>- Analysis of Surplus calculations</li>
                    <li>- Embedded value calculations</li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="max-w-7xl mx-auto">
              

              <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                {/* <!-- General and Health Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Actuarial Expertise
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> Leverage precise, data-driven methodologies to evaluate and optimize employee benefits.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Compliance Focus
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Ensure seamless adherence to regional and global regulations, avoiding legal and financial pitfalls.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Cost-Effective Solutions
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Strike the right balance between employee satisfaction and financial efficiency.</li>
                      
                    </ul>
                  </div>
                </div>

                
              </div>
            </div>

            {/* ......................................................... */}

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto align-middle">
                <div class="text-left">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                  Key Outcomes for Clients
                  </h2>
                  
                </div>
                {/* <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                      Improved financial stability and accuracy in projections.
                      </h3>
                     
                    </div>
                  </div>
                  

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900  text-center ">
                        Valuation Actuary
                      </h3>
                      
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                        Pricing Actuary
                      </h3>
                      
                    </div>
                  </div>

                 

                
                </div> */}
                <div >
                <ul class="list-disc ml-6">
                      <li className="text-xl">Enhanced employee satisfaction through meaningful and secure benefit programs.</li>
                      <li className="text-xl">Streamlined financial planning and reduced liability risks.</li>
                      <li className="text-xl">Confidence in regulatory compliance and audit readiness.</li>
                      
                    </ul>
                </div>
                
              </div>
              <div class="max-w-7xl mx-auto align-middle">
                <div class="text-left mt-4">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                  Real-World Impact
                  </h2>
                  
                </div>
                {/* <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                      Improved financial stability and accuracy in projections.
                      </h3>
                     
                    </div>
                  </div>
                  

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900  text-center ">
                        Valuation Actuary
                      </h3>
                      
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                        Pricing Actuary
                      </h3>
                      
                    </div>
                  </div>

                 

                
                </div> */}
                <div >
                <ul class="list-disc ml-6">
                      <li className="text-xl">RJAC helped a mid-sized corporate redesign their employee benefit structure, resulting in a 20% reduction in long-term liabilities and improved employee satisfaction scores.</li>
                      
                      
                    </ul>
                </div>
                
              </div>
            </div>
          </section>
        </div>
          </section>
        </div>
      </section>
    </div>
  );
}
